import React from 'react';
import SectionHeader from './SectionHeader';

import './About.scss';

const About = () => {

  return (
    <section id="about-section">
      <div className='container'>
        <SectionHeader>Hi, I'm Evan</SectionHeader>
        <div className='section-text'>
          <p>
            I'm a Web Developer with a passion for creating intuitive, responsive, and user-friendly web applications.
            With over four years of experience in the industry, I have honed my skills in both front-end and back-end development, working with a wide array of technologies and frameworks.
          </p>
          <p>
            Throughout my career, I have had the opportunity to work on a diverse set of projects,
            ranging from creating responsive websites for startups to implementing robust features for established businesses.
            My adaptability and commitment to staying up-to-date with emerging trends and technologies in the field enable me to
            deliver high-quality solutions that cater to the unique needs of each client.
          </p>
          <p>
            Here are a few technologies I have been working with recently:
          </p>
        </div>
        <ul>
          <li>React</li>
          <li>Vue</li>
          <li>Firebase</li>
          <li>JavaScript</li>
          <li>NodeJS</li>
          <li>PHP</li>
          <li>Laravel</li>
        </ul>
      </div>
    </section>
  )
}

export default About;